import React, { Component } from 'react';
import RevealLines from '../partials/revealLines';

class LinkListLink extends Component {

    render() {
        const {link, title, year} = this.props;

        return (
            <li>
                <div className="py-8 lg:py-16 block px-12">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <RevealLines className="mb-6 lg:mb-12">
                            <h3 className="tracking-tight">{title}</h3>
                        </RevealLines>
                        <RevealLines>
                            <p className="uppercase text-base font-normal">{year}</p>
                        </RevealLines>
                    </a>
                </div>
            </li>
        );
    }
}

export default LinkListLink;