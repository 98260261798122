import React, { Component } from 'react';
import MobileDetect from 'mobile-detect';
import {isTouchDevice} from '../utils';

class Image extends Component {
    componentDidMount() {
      
        var md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() || isTouchDevice()) return;

        this.refs.root.classList.add('opacity-0');

        window.dispatchEvent(new CustomEvent("addImageToCanvas", {
            detail: {
                src: this.props.src,
                elm: this.refs.root
            }
        }));
    }
    render() {

        var format = this.props.format ? 'aspect-ratio-' + this.props.format : 'aspect-ratio-square';
        return (
            <div className={`relative w-full h-0 bg-sand ${format}`} ref="root">
                <img className="object-cover object-center absolute w-full h-full block" src={this.props.src} alt=""/>
            </div>
        );
    }
}

export default Image;