import React, { Component } from 'react';

class SkewScroll extends Component {

    constructor() {
        super();

        this.onScrollUpdate = this.onScrollUpdate.bind(this);
    }
    
    componentDidMount() {
        
        window.addEventListener("onUpdate", this.onScrollUpdate);

    }

    onScrollUpdate(e) {
        
        const { velocity } = e.detail;
        this.refs.root.style.transform = `skewY(${velocity * -14}deg)`;
    }

    render() {
        return (
            <div ref="root" className={"wc-transform " + this.props.className && this.props.className } ref="root">
                {this.props.children}
            </div>
        );
    }
}

export default SkewScroll;