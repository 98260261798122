import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Poster, LinkList, BillBoard, Text, ImageGrid } from '../components/modules';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <BillBoard>
      <>
        <span className="block text-center">Creative Frontend</span>
        <span className="block text-center">Development</span>
      </>
    </BillBoard>
    <Poster />
    <LinkList /> 
    <ImageGrid />
    <Text />
  </Layout>
);

export default IndexPage;
