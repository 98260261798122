import React from "react";
import img from '../../images/face.jpg';
import Image from '../partials/image';

const Poster = () => (
    <div className="wrapper my-32">
        <div className="grid">
            <div className="col-1-16 mb-6">
                <Image src={img} format="square" />
            </div>
            <div className="col-1-16 lg-col-9-16"> 
                <p className="text-sm lg:text-base leading-none block uppercase">I’ve spent the last 7+ years building websites and honing my craft to a point where all that matters is imagination, creativity and solving the problem that your business or users face.  My focus is always on modularity, beautiful design and smooth collaboration.</p>
            </div>
        </div>

    </div>
);

export default Poster;