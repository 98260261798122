import React, { Component } from 'react';
import MobileDetect from 'mobile-detect';
import { isTouchDevice } from '../utils';

class RevealLines extends Component {

    constructor() {
        super();

        this.state = {
            isInView : false
        }
    }
    
    componentDidMount() {

        var md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() || isTouchDevice()) return;

        window.addEventListener("onUpdate", this.onUpdate);

        this.offset = this.refs.root.offsetTop;
        this.vh = window.innerHeight;
    }

    onUpdate = (e) => {
        
        const { currentScroll } = e.detail;
        
        if(currentScroll + this.vh*0.9 > this.offset) {
            this.setState({
                isInView: true
            }, () => {
                window.removeEventListener("onUpdate", this.onUpdate);
            });
        }
    }

    render() {
        return (
            <div ref="root" className={`oveflow-hidden revealLines ${this.state.isInView ? 'in-view' : ''} ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}

export default RevealLines;