import React from "react";
import SkewScroll from '../partials/skewScroll';
import RevealLines from '../partials/revealLines';

const BillBoard = ({children}) => (
    <SkewScroll className="wrapper my-32">
        <div className="grid">
            <div className="col-1-16">
                <h1 className="text-xl lg:text-3xl leading-sub font-light uppercase tracking-tight">
                    <RevealLines>
                        {children}
                    </RevealLines>
                </h1>
                <p className="text-sm lg:text-base text-center mt-12 lg:mt-32 uppercase leading-none">Jacob Frederiksen</p>
            </div>
        </div>
    </SkewScroll>
);

export default BillBoard;