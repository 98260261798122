import React from "react";
import SkewScroll from '../partials/skewScroll';
import LinkListLink from './linkListLink';

import img1 from '../../images/01.jpg';
import img2 from '../../images/02.jpg';
import img3 from '../../images/03.jpg';
import img4 from '../../images/04.jpg';
import img5 from '../../images/05.jpg';

const LinkList = () => (
    <SkewScroll className="wrapper my-16">
        <div className="grid">
            <div className="col-1-16">
                <ul className="text-xl lg:text-3xl font-light leading-none text-center uppercase">
                    <LinkListLink link="https://everland.dk/" title="Everland" year="2021" img={img1} />
                    <LinkListLink link="https://gmeadow.com/" title="Green Meadow" year="2020" img={img1} />
                    <LinkListLink link="https://www.honeytrapfilm.com/" title="Honeytrap" year="2020" img={img1} />
                    <LinkListLink link="https://ddd.kontrapunkt.com/" title="DDD" year="2020" img={img1} />
                    <LinkListLink link="https://apollobar.dk/" title="Apollobar" year="2020" img={img1} />
                    <LinkListLink link="https://away-kit.com/" title="Away kit" year="2020" img={img1} />
                    <LinkListLink link="https://anonybit.io/" title="Anonybit" year="2020" img={img1} />
                    <LinkListLink link="https://uia2023cph.org" title="Uia2023" year="2019" img={img2} />
                    <LinkListLink link="https://uzenergy.com/" title="Uzenergy" year="2019" img={img3} />
                    <LinkListLink link="https://mobilepay.dk/" title="Mobilepay" year="2017" img={img5} />
                </ul>
            </div>
        </div>
    </SkewScroll>
);

export default LinkList;