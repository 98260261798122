import React from "react";
import img from '../../images/office.jpg';
import caseImg from '../../images/uia.jpg';
import Image from '../partials/image';

const ImageGrid = () => (
    <div className="wrapper my-32">
        <div className="grid">
            <div className="col-1-8 relative">
                <Image src={img} format="square" alt=""/>
                <p className="text-sm lg:text-base leading-none block uppercase mt-6">The office</p>
            </div>
            
            <div className="col-9-16 relative">
                <Image src={caseImg} format="square" alt=""/>
                <p className="text-sm lg:text-base leading-none block uppercase mt-6 text-right">UIA2013</p>
            </div>
        </div>
    </div>
);

export default ImageGrid;