import React from "react";
import SkewScroll from '../partials/skewScroll';
import RevealLines from '../partials/revealLines';

const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Text = () => (
    <SkewScroll className="wrapper my-32 lg:my-64">
       <div className="grid">
            <div className="col-2-15 text-center">
                <h2 className="text-lg lg:text-2xl mb-12 lg:mb-32 leading-none font-light uppercase">
                    <RevealLines>
                            <span>Currently based in Denmark. I work with </span> 
                            <span>agencies, companies and people all over the </span> 
                            <span>world, creating rich experiences using the </span> 
                            <span>basic building blocks of the www.</span>
                    </RevealLines>
                </h2>
                <RevealLines>
                    <p className="text-xs lg:text-base uppercase leading-none">Available for work: {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}</p>
                </RevealLines>
            </div>       
       </div>
    </SkewScroll>
);

export default Text;